<template>
  <CModal id="OrderSendNotify" class="ActionModel" size="lg" :show.sync="Toggle" :centered="true" @update:show="HandleHideModal">
    <CAlert color="info">
      <CIcon name="cil-bell" /> 若有會員未填寫手機號碼，系統將會忽略此會員的簡訊發送。
    </CAlert>
    <CInput :label="'收件人'" :value="`${MemberInfo.Name}(${MemberInfo.Phone})`" horizontal readonly />
    <CInput :label="'主旨'" v-model="Title" horizontal />
    <CRow form class="form-group">
      <CCol tag="label" sm="3" class="col-form-label">
        訊息內容
      </CCol>
      <CCol sm="9">
        <CTextarea v-model="Content" />
      </CCol>
    </CRow>
    <CElementCover v-if="(Loading === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.SendNotifyMessage') }}</h5>
        <CButtonClose @click="HandleHideModal()" />
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="secondary">{{ $t('Global.Close') }}</CButton>
        <CButton @click="Send()" color="success" :disabled="(Content === '' || Title === '' || !MemberInfo.Phone)">{{ $t('Global.Submit') }}</CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "OrderSendSMS",
  props: {
    Toggle: {
      type: Boolean,
      default: false
    },
    MemberInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    OrderData: {
      type: Object,
      default () {
        return {}
      }
    },
    MessageType: {
      type: String,
      default: 'Custom'
    }
  },
  data () {
    return {
      Title: '',
      Content: '',
      Component: null,
      Template: 'Default',
      Loading: false,
      MessageTypeOptions: {
        Unpaid: {
          Title: '訂單未付款通知',
          Content: `【${JSON.parse(localStorage.getItem('Permission')).ProjectTitle}】您的訂單${this.OrderData.OrderNum}至今仍尚未付款，請您抽空進行付款：https://${this.$store.state.user.permission.Domain}/cart/confirm?id=${this.OrderData._id}`
        },
        Custom: {
          Title: '',
          Content: ''
        }
      }
    }
  },
  computed: {
  },
  mounted() {
    if (typeof this.MessageTypeOptions[this.MessageType] === 'object') {
      this.Title = this.MessageTypeOptions[this.MessageType].Title
      this.Content = this.MessageTypeOptions[this.MessageType].Content
    }
  },
  methods: {
    HandleHideModal () {
      this.$emit('update:Toggle', false)
    },
    Send() {
      if (!this.MemberInfo.Phone) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Message/EmptyPhone'),
          type: 'error'
        })
        return false
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/notify/sms/send',
        method: 'post',
        data: {
          Message: this.Content,
          Subject: this.Title || '',
          Phone: [this.MemberInfo.Phone]
        }
      }).then(() => {
        this.Loading = false
        this.HandleHideModal()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Message/SendSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Message/SendFail'),
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#OrderSendNotify {
  label {
    font-weight: bold;
  }
}
</style>
